import React, { useEffect, useState } from "react";
import Table from "../extra/Table";
import Pagination from "../extra/Pagination";
import axios from "axios";
import { baseURL } from "../util/config";
import { ToastContainer, toast } from 'react-toastify';

const CustomerWithdrawal = () => {
    const [withdrawals, setWithdrawals] = useState([]);
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);

    useEffect(() => {
        fetchWithdrawals();
    }, [page, size]);

    const fetchWithdrawals = async () => {
        try {
            const response = await axios.get(`${baseURL}client/withdraw?page=${page}&limit=${size}`);
            setWithdrawals(response.data.withdrawals);
            setPagination(response.data.pagination);
        } catch (error) {
            console.error("Error fetching withdrawals:", error);
        }
    };


    const handleStatusChange = async (id, newStatus, currentStatus) => {
        // Don't show confirmation if the status hasn't changed
        if (newStatus === currentStatus) {
            return;
        }

        const isConfirmed = window.confirm(`Are you sure you want to change the status to ${newStatus}?`);

        if (isConfirmed) {
            try {
                await axios.put(`${baseURL}client/withdraw/${id}/status`, { status: newStatus });
                toast.success("Status updated successfully");
                fetchWithdrawals();
            } catch (error) {
                toast.error(error.response?.data?.error || "An error occurred while updating status");
                console.error("Error updating withdrawal status:", error.response?.data?.error);
                fetchWithdrawals();
            }
        } else {
            // Reset the select element to its original value
            fetchWithdrawals();
        }
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handleRowsPerPage = (value) => {
        setPage(1);
        setSize(value);
    };

    const withdrawalTable = [
        {
            Header: "NO",
            Cell: ({ row, index }) => (
                <span className="text-nowrap">
                    {(page - 1) * size + parseInt(index) + 1}
                </span>
            ),
        },
        {
            Header: "USER NAME",
            Cell: ({ row }) => (
                <span className="text-capitalize text-nowrap">{row.userId.fullName}</span>
            ),
        },
        {
            Header: "AMOUNT",
            Cell: ({ row }) => (
                <span className="text-capitalize">{row.amount}</span>
            ),
        },
        {
            Header: "ACCOUNT NUMBER",
            Cell: ({ row }) => (
                <span>{row.accountNumber}</span>
            ),
        },
        {
            Header: "IFSC CODE",
            Cell: ({ row }) => (
                <span>{row.ifscCode}</span>
            ),
        },
        {
            Header: "ACCOUNT HOLDER NAME",
            Cell: ({ row }) => (
                <span>{row.accountHolderName}</span>
            ),
        },
        {
            Header: "STATUS",
            Cell: ({ row }) => (
                <select
                    value={row.status}
                    onChange={(e) => handleStatusChange(row._id, e.target.value, row.status)}
                    className="form-control"
                >
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                </select>
            ),
        },
        {
            Header: "CREATED AT",
            Cell: ({ row }) => (
                <span className="text-capitalize">
                    {new Date(row.createdAt).toLocaleDateString()}
                </span>
            ),
        },
    ];

    return (
        <div className="userPage withdrawal-page">

            <div className="dashboardHeader primeHeader mb-3 p-0">
                <h2>Customer Withdrawals</h2>
            </div>
            <ToastContainer />
            <div className="payment-setting-box user-table">
                <div className="row align-items-center mb-2 p-3 ml-1">
                    <div className="col-12">
                        <h5
                            style={{
                                fontWeight: "500",
                                fontSize: "20px",
                                marginBottom: "0px",
                                marginTop: "5px",
                                padding: "3px",
                            }}
                        >
                            Customer Withdrawal Table
                        </h5>
                    </div>
                </div>
                <div className="mt-3">
                    <Table
                        data={withdrawals}
                        mapData={withdrawalTable}
                        serverPerPage={size}
                        serverPage={page}
                        type={"server"}
                    />
                    <div className="mt-3">
                        <Pagination
                            type={"server"}
                            activePage={page}
                            actionShow={false}
                            rowsPerPage={size}
                            userTotal={pagination.totalItems}
                            setPage={setPage}
                            handleRowsPerPage={handleRowsPerPage}
                            handlePageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerWithdrawal;