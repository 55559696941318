import React, { useEffect, useState } from "react";
import NewTitle from "../../extra/Title";
import MultiButton from "../../extra/MultiButton";
import UserProfileSetting from "./UserProfileSetting";
import PasswordSetting from "./PasswordSetting";
import AvatarSetting from "./AvatarSetting";
import { connect, useDispatch, useSelector } from "react-redux";
import Button from "../../extra/Button";
import { getUserProfile } from "../../store/user/user.action";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import { useLocation, useNavigate } from "react-router-dom";

function UserSetting(props) {
  const {
    multiButtonSelectNavigateSet,
    multiButtonSelectNavigate,
    multiButtonSelectData,
  } = props;
  const [multiButtonSelect, setMultiButtonSelect] = useState("Profile");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const location = useLocation();
  const { userProfile, countryData } = useSelector((state) => state.user);

  const handleClose = () => {
    if (dialogueData) {
      dispatch({
        type: CLOSE_DIALOGUE,
        payload: {
          dialogue: false,
        },
      });

      let dialogueData_ = {
        dialogue: false,
      };
      localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (dialogueData) {
      dispatch(getUserProfile(dialogueData?._id));
    } else {
      dispatch(getUserProfile(location?.state?.id));
    }
  }, [dispatch, location?.state?.id]);
  return (
    <div className="userSetting">
      <div className="dashboardHeader primeHeader mb-3 p-0">
        <NewTitle name={`User / ${multiButtonSelect}`} />
      </div>
      <div className="row">
        <div className="col-7">
          <div className="multi-user-btn mb-3 pb-2">
            <MultiButton
              multiButtonSelect={multiButtonSelect}
              setMultiButtonSelect={setMultiButtonSelect}
              label={
                multiButtonSelectData === "Fake User"
                  ? ["Profile", "Password", "Avatar"]
                  : ["Profile", "Password", "Avatar"]
              }
            />
          </div>
        </div>
        <div className="col-5">
          <Button
            btnName={"Back"}
            newClass={"back-btn"}
            onClick={handleClose}
          />
        </div>
      </div>

      {multiButtonSelect === "Profile" && (
        <UserProfileSetting
          multiButtonSelect={multiButtonSelect}
          setMultiButtonSelect={setMultiButtonSelect}
          multiButtonSelectNavigate={multiButtonSelectNavigate}
          multiButtonSelectNavigateSet={multiButtonSelectNavigateSet}
        />
      )}
      {multiButtonSelect === "Password" && <PasswordSetting />}
      {multiButtonSelect === "Avatar" && <AvatarSetting />}
    </div>
  );
}
export default connect(null, {
  getUserProfile,
})(UserSetting);
