import React, { useEffect, useState } from "react";
//material-ui
import IconButton from "@material-ui/core/IconButton";
import {  makeStyles, useTheme } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import {ReactComponent as FirstPageIcon} from '../../assets/icons/FirstPageIcon.svg'
import {ReactComponent as LastPageIcon} from '../../assets/icons/LastPageIcon.svg'
import { Box, FormControl, MenuItem,InputLabel, Select, } from "@mui/material";
import TablePagination from "react-js-pagination";
import Button from "./Button";
import Selector from "./Selector";

//useStyle
const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  pageNumber: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
  },
  pageNumberButton: {
    border: "1px solid #C9C9C9",
    margin: theme.spacing(0, 0.5),
    borderRadius: "50px",
    cursor: "pointer",
    color: "#000",
    width:"45px",
    height:"45px",
    backgroundColor: "#fff",
  },
  activePageNumberButton: {
    backgroundColor: "#FF4D67",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#f36278",
    },
  },
}));

const Pagination = (props) => {
  const [pages, setPages] = useState([]);

  const {
    customSelectDataShow,
    customSelectData,
    type,
    // server props
    setPage,
    userTotal,
    rowsPerPage,
    activePage,
    actionShow,
    // client props
    size,
    totalData,
    data,
    setData,
    // selector
    setActionPagination,
    actionPagination,
    paginationSubmitButton,
    setActivePage,
  } = props;

  const [clientData,setClientData]=useState()

  const handlePage = (pageNumber) => {
    setPage(pageNumber); 
  };


  const totalPages = Math.ceil(userTotal / rowsPerPage);

  return (
    <>
    {
      userTotal > rowsPerPage &&(
    <div className=" row gx-0 custom-pagination m-0 w-100 px-3">
      <>
      {/* <div className='col-5 pagination-select'>
      {
        actionShow === false ?
        "" :
    <>
     <div className='pagination-submit me-3'>
      <FormControl>
     <InputLabel id="demo-simple-select-label" style={{fontWeight:"500",fontSize:"17px",marginBottom:"10px"}}>Action</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={actionPagination}
          label="Action"
          onChange={(e)=>setActionPagination(e.target.value)}
        >
          {
            customSelectDataShow ?
            customSelectData?.map((item)=>{
              return(
                <MenuItem value={item?.toLowerCase()} key={item}>{item}</MenuItem>
              )
            })
            :
            paginationActionData?.map((item)=>{
              return(
                <MenuItem value={item?.toLowerCase()} key={item}>{item}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
                    
      </div>
    <Button
                      newClass={"submit-btn"}
                      onClick={paginationSubmitButton}
                        btnName={"Submit"}
                      />
    </>
                    }
                    </div> */}

        {/* {type === "server" && totalData > 0 && (
          <>
    <div>
    <span style={{fontWeight:"500",marginBottom:"5px"}}>{`Showing ${serverPage} out of ${totalData}`}</span>
         <div>
         <button
              className={serverPage == 1 ? "page-btn" : "active"}
              disabled={serverPage == 1}
              onClick={() => onPageChangeClient(1)}
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <g clip-path="url(#clip0_86_229)">
    <path d="M17.8647 14.5212L17.8647 14.5212C18.0153 14.6718 18.0999 14.8761 18.0999 15.0891C18.0999 15.3021 18.0153 15.5064 17.8647 15.657C17.7141 15.8076 17.5098 15.8922 17.2968 15.8922C17.0838 15.8922 16.8795 15.8076 16.7289 15.657L10.6398 9.56793C10.6398 9.56793 10.6398 9.56793 10.6398 9.56793C10.5652 9.49335 10.506 9.40481 10.4657 9.30737C10.4253 9.20992 10.4045 9.10548 10.4045 9C10.4045 8.89452 10.4253 8.79008 10.4657 8.69264C10.506 8.59519 10.5652 8.50665 10.6398 8.43208C10.6398 8.43207 10.6398 8.43207 10.6398 8.43207L16.7288 2.34308L17.8647 14.5212ZM17.8647 14.5212L12.3436 9.00007L17.8647 3.47898C17.8647 3.47898 17.8647 3.47897 17.8647 3.47897C17.977 3.36665 18.0536 3.22354 18.0846 3.06774C18.1156 2.91193 18.0997 2.75043 18.0389 2.60367C17.9781 2.4569 17.8751 2.33145 17.743 2.2432C17.6109 2.15495 17.4556 2.10786 17.2968 2.10788M17.8647 14.5212L17.2968 2.10788M17.2968 2.10788C17.1913 2.10775 17.0868 2.12846 16.9894 2.1688C16.8919 2.20915 16.8034 2.26835 16.7289 2.34301L17.2969 2.10788C17.2969 2.10788 17.2968 2.10788 17.2968 2.10788ZM1.27093 3.20092C1.12031 3.05031 0.916033 2.9657 0.70303 2.9657C0.490028 2.9657 0.285751 3.05031 0.135136 3.20092C-0.01548 3.35154 -0.100096 3.55582 -0.100096 3.76882L-0.100096 14.2313C-0.100096 14.4443 -0.01548 14.6486 0.135134 14.7992C0.285751 14.9498 0.490028 15.0344 0.70303 15.0344C0.916033 15.0344 1.12031 14.9498 1.27093 14.7992C1.42154 14.6486 1.50616 14.4443 1.50616 14.2313L1.50616 3.76882C1.50616 3.55582 1.42154 3.35154 1.27093 3.20092Z" fill="black" stroke="black" stroke-width="0.2"/>
    <path d="M6.25455 9.00008L11.7757 14.5212C11.9263 14.6718 12.0109 14.8761 12.0109 15.0891C12.0109 15.1946 11.9901 15.299 11.9498 15.3964C11.9094 15.4939 11.8502 15.5824 11.7757 15.657C11.7011 15.7316 11.6125 15.7907 11.5151 15.8311C11.4177 15.8715 11.3132 15.8922 11.2077 15.8922C10.9947 15.8922 10.7905 15.8076 10.6398 15.657L4.55078 9.56794C4.55078 9.56794 4.55078 9.56794 4.55078 9.56794C4.47619 9.49336 4.41703 9.40482 4.37666 9.30738C4.33629 9.20993 4.31552 9.10549 4.31552 9.00001C4.31552 8.89454 4.33629 8.79009 4.37666 8.69265C4.41703 8.5952 4.47619 8.50666 4.55078 8.43209C4.55078 8.43209 4.55078 8.43208 4.55078 8.43208L10.6398 2.3431L6.25455 9.00008ZM6.25455 9.00008L11.7756 3.47891C11.8879 3.36659 11.9644 3.22349 11.9954 3.0677C12.0264 2.91191 12.0105 2.75043 11.9497 2.60368C11.8889 2.45693 11.786 2.3315 11.6539 2.24325C11.5218 2.15503 11.3666 2.10792 11.2078 2.10789C11.2078 2.10789 11.2077 2.10789 11.2077 2.10789L11.2077 2.20789L6.25455 9.00008Z" fill="black" stroke="black" stroke-width="0.2"/>
  </g>
  <defs>
    <clipPath id="clip0_86_229">
      <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 -1 18 18)"/>
    </clipPath>
  </defs>
</svg>
            </button>
            {pages.map((serverPage) => (
              <button
                key={serverPage}
                onClick={() => onPageChangeClient(serverPage - 1)}
                className={
                  serverPage + 1 === serverPage ? "active" : "active-btn"
                }
              >
                {serverPage}
              </button>
            ))}
            {renderPageNumbers()}
            <button
              className={
                serverPage === Math.ceil(totalData / serverPerPage)
                  ? "page-btn"
                  : "active"
              }
              style={{marginRight:"9px",marginLeft:"8px",color:"#000000"}}
              disabled={serverPage === Math.ceil(totalData / serverPerPage)}
              onClick={() => onPageChangeClient(serverPage + 1)}
            >
              <i className="fa-solid fa-angle-right"></i>
            </button>
            <button
              className={
                serverPage === Math.ceil(totalData / serverPerPage)
                  ? "page-btn"
                  : "active"
              }
              disabled={serverPage === Math.ceil(totalData / serverPerPage)}
              onClick={() =>
                onPageChangeClient(Math.ceil(totalData / serverPerPage))
              }
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <g clip-path="url(#clip0_86_225)">
    <path d="M0.135296 3.47883L0.135294 3.47883C-0.0153238 3.3282 -0.099938 3.12392 -0.0999343 2.91091C-0.0999305 2.69789 -0.015309 2.49361 0.135315 2.34299C0.285938 2.19237 0.490225 2.10776 0.703235 2.10776C0.916245 2.10777 1.12053 2.19239 1.27115 2.34301L7.3602 8.43207C7.36021 8.43207 7.36021 8.43207 7.36021 8.43207C7.43479 8.50665 7.49396 8.59519 7.53433 8.69263C7.57469 8.79008 7.59547 8.89452 7.59547 9C7.59547 9.10548 7.57469 9.20992 7.53433 9.30736C7.49396 9.40481 7.43479 9.49335 7.36021 9.56792C7.36021 9.56793 7.36021 9.56793 7.3602 9.56793L1.27122 15.6569L0.135296 3.47883ZM0.135296 3.47883L5.65643 8.99993L0.135305 14.521C0.135302 14.521 0.135299 14.521 0.135296 14.521C0.0229539 14.6333 -0.0535561 14.7765 -0.0845577 14.9323C-0.11556 15.0881 -0.0996587 15.2496 -0.0388648 15.3963C0.0219291 15.5431 0.124884 15.6685 0.256979 15.7568C0.389074 15.845 0.544373 15.8921 0.703235 15.8921M0.135296 3.47883L0.703235 15.8921M0.703235 15.8921C0.808706 15.8922 0.913165 15.8715 1.01061 15.8312C1.10808 15.7908 1.19662 15.7316 1.27115 15.657L0.703082 15.8921C0.703133 15.8921 0.703184 15.8921 0.703235 15.8921ZM16.7291 14.7991C16.8797 14.9497 17.084 15.0343 17.297 15.0343C17.51 15.0343 17.7142 14.9497 17.8649 14.7991C18.0155 14.6485 18.1001 14.4442 18.1001 14.2312V3.76868C18.1001 3.55568 18.0155 3.3514 17.8649 3.20078C17.7142 3.05017 17.51 2.96555 17.297 2.96555C17.084 2.96555 16.8797 3.05017 16.7291 3.20078C16.5785 3.3514 16.4938 3.55568 16.4938 3.76868V14.2312C16.4938 14.4442 16.5785 14.6485 16.7291 14.7991Z" fill="black" stroke="black" stroke-width="0.2"/>
    <path d="M11.7452 8.99992L6.2241 3.47881C6.07348 3.3282 5.98887 3.12391 5.98887 2.91091C5.98887 2.80544 6.00964 2.701 6.05 2.60356C6.09036 2.50612 6.14952 2.41758 6.2241 2.343C6.29868 2.26842 6.38722 2.20926 6.48466 2.1689C6.5821 2.12854 6.68654 2.10776 6.79201 2.10776C7.00502 2.10776 7.2093 2.19238 7.35992 2.343L13.449 8.43206C13.449 8.43206 13.449 8.43206 13.449 8.43206C13.5236 8.50664 13.5827 8.59518 13.6231 8.69262C13.6635 8.79007 13.6842 8.89451 13.6842 8.99999C13.6842 9.10546 13.6635 9.20991 13.6231 9.30735C13.5827 9.4048 13.5236 9.49334 13.449 9.56791C13.449 9.56791 13.449 9.56792 13.449 9.56792L7.35999 15.6569L11.7452 8.99992ZM11.7452 8.99992L6.22417 14.5211C6.11186 14.6334 6.03537 14.7765 6.00438 14.9323C5.97339 15.0881 5.9893 15.2496 6.05008 15.3963C6.11087 15.5431 6.2138 15.6685 6.34587 15.7568C6.47791 15.845 6.63313 15.8921 6.79193 15.8921C6.79197 15.8921 6.79202 15.8921 6.79206 15.8921L6.79206 15.7921L11.7452 8.99992Z" fill="black" stroke="black" stroke-width="0.2"/>
  </g>
  <defs>
    <clipPath id="clip0_86_225">
      <rect width="18" height="18" fill="white"/>
    </clipPath>
  </defs>
</svg>
            </button>
         </div>
    </div>
 
          </>
        )} */}
                <div className="col-12 pagination d-flex flex-column  mt-3 mb-3" style={{marginRight:"10px"}}>
        {
           (type === "server" && userTotal > rowsPerPage) &&(
        <>
          <div>
          <p style={{marginBottom:"0px",color:"1F1F1F",fontSize:"16px",fontWeight:"500"}}>Showing {activePage} out of {totalPages} pages</p>
            <TablePagination
            activePage={activePage}
            itemsCountPerPage={rowsPerPage}
            totalItemsCount={userTotal}
            pageRangeDisplayed={3}
            onChange={(page) => handlePage(page)}
            itemClass="page-item"
          />
          </div>
          </>
          )
        }
       <div>
       {
            (type === "client" && userTotal > rowsPerPage) &&(
            <>
            <p style={{marginBottom:"0px",color:"1F1F1F",fontSize:"16px",fontWeight:"500"}}>Showing {activePage} out of {totalPages} pages</p>
             <TablePagination
             activePage={activePage}
             itemsCountPerPage={rowsPerPage}
             totalItemsCount={userTotal}
             pageRangeDisplayed={3}
             onChange={handlePage}
             itemClass="page-item"
           />
            </>
          )
        }
        </div>
        </div>
      </>
      
      {/* client side pagination  */}
     
      {/* {type === "client" && (
        <>
          <div className="col-sm-12 col-md-4 gx-0">
            <p className="count">
                Showing <span className="fw-bold text-primary">{parseInt(serverPage * serverPerPage + 1)}</span> - <span className="fw-bold text-primary">{totalCount}</span> From <span className="fw-bold text-primary">{totalData}</span>
            </p>
          </div>

          <div className="col-sm-12 col-md-8 boxCenter justify-content-end gx-0" style={{cursor:"ponter"}}>
            <div>
              <span className="mt-2">Display:</span>
              <select
                name=""
                id=""
                className="mx-2 pageOption"
                onChange={(e) => onRowsPerPageChange(e.target.value)}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15" selected>
                  20
                </option>
                <option value="20">50</option>
                <option value="20">100</option>
                <option value={totalData}>All</option>
              </select>
              <span>Per Page</span>
            </div>
            <div className={classes.root} style={{ display: "flex" }}>
              <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={serverPage === 0}
                aria-label="first page"
              >
                {theme.direction === "rtl" ? (
                  <LastPageIcon />
                ) : (
                  <FirstPageIcon />
                )}
              </IconButton>
              <IconButton
                onClick={handleBackButtonClick}
                disabled={serverPage === 0}
                aria-label="previous page"
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </IconButton>
              <IconButton
                onClick={handleNextButtonClick}
                disabled={serverPage >= Math.ceil(count / serverPerPage) - 1}
                aria-label="next page"
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </IconButton>
              <IconButton
                onClick={handleLastPageButtonClick}
                disabled={serverPage >= Math.ceil(count / serverPerPage) - 1}
                aria-label="last page"
              >
                {theme.direction === "rtl" ? (
                  <FirstPageIcon />
                ) : (
                  <LastPageIcon />
                )}
              </IconButton>
            </div>
          </div>
        </>
      )} */}
    </div>
      )
    }
    </>
  );
};
export default Pagination;

