// import $ from "jquery";
// $(document).ready(function () {
//   $(".subMenu").hide();

//   $(".mainMenu > li > a").click(function () {
//     $(this).next(".subMenu").slideToggle();
//     // $(this).next(".subMenu").slideDown("slow");
//     // $(this).next(".subMenu").toggleClass("transform1 transform0");
//     $(this).children("i").toggleClass("rotate90");
//   });

//   $(".navToggle").click(function () {
//     $(".mainNavbar").toggleClass("mobNav webNav");
//     $(".sideBar").toggleClass("mobSidebar webSidebar");
//     $(".mainAdmin").toggleClass("mobAdmin");
//   });
// });
