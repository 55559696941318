// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {baseURL} from "../util/config";

function Transaction() {
    const [transactions, setTransactions] = useState([]);
    const [search, setSearch] = useState('');
    const [type, setType] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchTransactions();
    }, [search, type, currentPage]);

    const fetchTransactions = async () => {
        try {
            const response = await axios.get(`${baseURL}transactions?search=${search}&type=${type}&page=${currentPage}&limit=10`);
            setTransactions(response.data.transactions);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };
``
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="container mt-5">
            <h1 className="mb-4">Transaction Filter</h1>
            <div className="row mb-3">
                <div className="col">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search transactions..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="col">
                    <select
                        className="form-control"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        <option value="">All Types</option>
                        <option value="withdraw">Withdraw</option>
                        <option value="subscribe">Subscribe</option>
                        <option value="share">Share</option>
                        <option value="like">Like</option>
                        <option value="comment">Comment</option>
                        <option value="watch">Watch</option>
                    </select>
                </div>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th>Points Credited</th>
                    <th>Points Debited</th>
                </tr>
                </thead>
                <tbody>
                {transactions.map((transaction) => (
                    <tr key={transaction._id}>
                        <td>{transaction.user_id?.fullName || 'N/A'}</td>
                        <td>{transaction.user_id?.email || 'N/A'}</td>
                        <td>{transaction.type}</td>
                        <td>{transaction.points_credited}</td>
                        <td>{transaction.points_debited}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <nav>
                <ul className="pagination">
                    {[...Array(totalPages).keys()].map((page) => (
                        <li key={page + 1} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(page + 1)}>
                                {page + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}

export default Transaction;