export const baseURL = "https://api.anytimeott.com/";
export const secretKey = "kY4fai9sUT";

//aws credentials
export const ProjectName = "Anytime OTT"; //Project Name
export const bucket_name = "anytime-ott";
export const end_point = "https://anytime-ott.blr1.digitaloceanspaces.com";
export const aws_access_key_id = "DO008MUZ82QFUXJ6DH6W"; //your_access_key
export const aws_secret_access_key = "4Bv78mz0iOfyJ9z8zw8+5SDyLkz7a2AyKUCjosMXhBU"; //your_secret_key
export const folderStructurePath = "/"; //folderStructure Path
