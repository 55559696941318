import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseURL } from "../util/config";

const PointsConfigManager = () => {
    const [configs, setConfigs] = useState({
        like: 0,
        subscribe: 0,
        points_to_money: 0,
        share: 0,
        comment: 0,
        minWatchTime: 0,
        watchTimePoints: 0,
        watch: 0,
        minimum_withdrawal_amount: 0  // Added minimum withdrawal amount
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        fetchConfigs();
    }, []);

    const fetchConfigs = async () => {
        try {
            const response = await axios.get(baseURL + 'points');
            const newConfigs = { ...configs };
            response.data.forEach(config => {
                if (newConfigs.hasOwnProperty(config.type)) {
                    newConfigs[config.type] = config.points;
                }
            });
            setConfigs(newConfigs);
        } catch (err) {
            setError('Failed to fetch configurations');
        }
    };

    const handleInputChange = (type, value) => {
        setConfigs(prevConfigs => ({
            ...prevConfigs,
            [type]: parseInt(value, 10)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const configsArray = Object.entries(configs).map(([type, points]) => ({ type, points }));
            await axios.post(baseURL + 'points', { configs: configsArray });
            setSuccess(true);
            setError(null);
            setTimeout(() => setSuccess(false), 3000);
        } catch (err) {
            setError('Failed to update configurations');
            setSuccess(false);
        }
    };

    return (
        <div className="card">
            <div className="container mt-5">
                <h2 className="mb-4">Points Configuration</h2>
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                {success &&
                    <div className="alert alert-success" role="alert">Configurations updated successfully!</div>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="points_to_money" className="form-label">1 currency equivalent points</label>
                        <input
                            type="number"
                            className="form-control"
                            id="points_to_money"
                            value={configs.points_to_money}
                            onChange={(e) => handleInputChange('points_to_money', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="minimum_withdrawal_amount" className="form-label">Minimum Withdrawal
                            Amount</label>
                        <input
                            type="number"
                            className="form-control"
                            id="minimum_withdrawal_amount"
                            value={configs.minimum_withdrawal_amount}
                            onChange={(e) => handleInputChange('minimum_withdrawal_amount', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="like" className="form-label">Like</label>
                        <input
                            type="number"
                            className="form-control"
                            id="like"
                            value={configs.like}
                            onChange={(e) => handleInputChange('like', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="subscribe" className="form-label">Subscribe</label>
                        <input
                            type="number"
                            className="form-control"
                            id="subscribe"
                            value={configs.subscribe}
                            onChange={(e) => handleInputChange('subscribe', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="share" className="form-label">Share</label>
                        <input
                            type="number"
                            className="form-control"
                            id="share"
                            value={configs.share}
                            onChange={(e) => handleInputChange('share', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">Comment</label>
                        <input
                            type="number"
                            className="form-control"
                            id="comment"
                            value={configs.comment}
                            onChange={(e) => handleInputChange('comment', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="watch" className="form-label">Watch</label>
                        <input
                            type="number"
                            className="form-control"
                            id="watch"
                            value={configs.watch}
                            onChange={(e) => handleInputChange('watch', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="minWatchTime" className="form-label">Minimum Watch Time (Seconds)</label>
                        <input
                            type="number"
                            className="form-control"
                            id="minWatchTime"
                            value={configs.minWatchTime}
                            onChange={(e) => handleInputChange('minWatchTime', e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="watchTimePoints" className="form-label">Watch Time Points</label>
                        <input
                            type="number"
                            className="form-control"
                            id="watchTimePoints"
                            value={configs.watchTimePoints}
                            onChange={(e) => handleInputChange('watchTimePoints', e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Update Configurations
                    </button>
                </form>
            </div>
        </div>
    );
};

export default PointsConfigManager;